/* eslint-disable array-callback-return */
import { React, useState,useContext } from "react";
import { useTranslation } from "react-i18next";
import { ValueContext } from "../../context/Context.js";
import { Input, Button, Form, Modal, Select, notification } from "antd";
import { useMutation, useLazyQuery } from "@apollo/client";
import { PlusOutlined } from "@ant-design/icons";
import { SEND_INVITAION } from "../../mutations/userMutations.js";
import { GET_USERS } from "../../queries/UserQuery.js";
import { GET_ROLES } from "../../queries/roleQuery.js";
import { GET_COMPANYS } from "../../queries/companyQuery.js";
import { GET_WORKSPACES } from "../../queries/workspaceQuery.js";
import Loading from "../Loading/Loading.js";
function CreateMemberForm(props) {
  const { isDesktop } = useContext(ValueContext);
  const { t } = useTranslation();
  const errorNotification = (error) => {
    notification.open({
      description: t(error),
      duration:3,
      closeIcon: false,
      className:'CustomSuccess',
      placement:'top'
    });
  };
  const successNotification = () => {
    notification.open({
      description: t("Notification.19"),
      duration:2,
      closeIcon: false,
      className:'CustomSuccess'
    });
  };
  const { company, role, workspaces,companyname } = props.props;
  const [open, setOpen] = useState(false);
  const [Work, setWork] = useState();
  const [form] = Form.useForm();
  const [getroles, { loading:loadingr , data: dataR }] =
    useLazyQuery(GET_ROLES, {
      variables: { role: role },
      onError: (error) => {
        errorNotification(error.message);
      },
    });
  const [getworkspace, { loading:loadingw , data: dataw }] =
    useLazyQuery(GET_WORKSPACES,{
      variables: { _id: company, role: role, workspace: workspaces },fetchPolicy: "no-cache",
      onError: (error) => {
        errorNotification(error.message);
      },
    });
  const [getCompanys, { loading , data }] = useLazyQuery(GET_COMPANYS,{
    onError: (error) => {
      errorNotification(error.message);
    },
  });
  const [sendInvitation] = useMutation(SEND_INVITAION, {
    onError: (error) => {
      errorNotification(error.message);
    },
    onCompleted: () => {
      successNotification();
    },
    refetchQueries: [
      {
        query: GET_USERS,
        variables: { _id: company, role: role, workspace: workspaces },
      },
    ],
  });
  const roleoptions = [];
  const roleoptions2 = [];
  dataR &&
    dataR.getRoles.map((ele) => {
      role === "Admin" || role === "Super Admin"
        ? ele.name !== "Super Admin" &&
          roleoptions.push({
            value: ele._id,
            label: t(ele.name),
          })
        : ele.name !== "Super Admin" &&
          ele.name !== "Admin" &&
          roleoptions.push({
            value: ele._id,
            label: t(ele.name),
          });
    });
  dataR &&
    dataR.getRoles.map((ele) => {
      ele.name === "Admin" &&
          roleoptions2.push({
            value: ele._id,
            label: t(ele.name),
          })
    });
  const companyoptions = [];
  role === "Super Admin" &&
    data &&
    data.getCompanys.map((ele) => {
      companyoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
  const workspaceoptions = [];
  dataw &&
    dataw.getWorkspaces.map((ele) => {
      workspaceoptions.push({
        value: ele._id,
        label: ele.name,
      });
    });
    console.log(Work);
  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          setOpen(true);
          getroles();
          if (role === "Super Admin") {
            getCompanys({ variables: { role: role } });
          } else {
            company &&
              getworkspace();
          }
        }}
      >
        {isDesktop? <div><PlusOutlined /> {t("AddMember")}</div>:<div><PlusOutlined /></div>}
      </Button>
      <Modal
        open={open}
        title={t("AddMember")}
        okText={t("Invite")}
        cancelText={t("Alerts.9")}
        style={{
          top: 20,
        }}
        onCancel={() => {
          form.resetFields();
          setOpen(false);
        }}
        onOk={() => {
          form
            .validateFields()
            .then((values) => {
              sendInvitation({
                variables: {
                  email: values.email,
                  role: values.role,
                  company: values.company ? values.company : company,
                  workspace: values.workspace ? (values.workspace.includes("All") ? (workspaceoptions.map(option => option.value)):values.workspace): [],
                  lang:localStorage.getItem('language') ?? 'en',
                },
              });

              setOpen(false);

              form.resetFields();
            })
            .catch((info) => {
              console.log("Validate Failed:", info);
            });
        }}
      >
        {loading || loadingr || loadingw ? <Loading/>:(<Form
          form={form}
          layout="vertical"
          name="form_in_modal"
          initialValues={{
            modifier: "public",
          }}
        >
          <Form.Item
            name="email"
            label={t("FormeAddUser.lableforemail")}
            rules={[
              {
                type: "email",
                message: t("FormValidations.16"),
              },
              {
                required: true,
                message: t("FormValidations.15"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          {role !== "Super Admin" && (
            <Form.Item
              name="workspace"
              label={t("FormeAddUser.lableforworkspace")}
              rules={[
              {
                required: true,
                message: t("FormValidations.41"),
              },
            ]}
            >
              <Select
                mode="multiple"
                style={{
                  width: "100%",
                }}
                onChange={(e)=>{
                  setWork(e);
                  form.resetFields(['role'])
                  }}
                filterOption={(input, option) =>
                (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
              }
                options={[{value: "All", label: companyname+" ("+t("Allspace")+") "},...workspaceoptions]}
              />
            </Form.Item>
          )}
          <Form.Item
            name="role"
            label={t("FormeAddUser.lableforrole")}
            rules={[
              {
                required: true,
                message: t("FormValidations.40"),
              },
            ]}
          >
            <Select
              style={{
                width: "100%",
              }}
              options={Work&&Work[0]==="All" ? roleoptions2:roleoptions}
            />
          </Form.Item>
          {role === "Super Admin" && (
            <Form.Item
              name="company"
              label={t("FormeAddUser.lableforcompany")}
              rules={[
                {
                  required: true,
                  message: t("FormValidations.25"),
                },
              ]}
            >
              <Select
                style={{
                  width: "100%",
                }}
                options={companyoptions}
              />
            </Form.Item>
          )}
        </Form>)}
      </Modal>
    </>
  );
}
export default CreateMemberForm;
