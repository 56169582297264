import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom"
import { useMutation } from '@apollo/client';
import { RESET_USERPASSWORD } from '../../mutations/userMutations.js';
import { useDispatch } from 'redux-react-hook';
import * as actions from '../../constants/action_types.js';
import { Button, Form, Input,Divider,Typography } from "antd";
import { Layout,Card } from "antd";
import '../../css/auth.css'
const { Content,Header } = Layout;
const { Title,Text } = Typography;

function ResetPassword() {
    const { t } = useTranslation();
    let params = useParams();
    const [form] = Form.useForm();
    const [loading,setLoading] = useState(false);
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const [resetUserPassword,{error}] = useMutation(RESET_USERPASSWORD);

    const handleClick = () => {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 5000); // 5 seconds delay
    };

    const submit = async () => {
      form
      .validateFields()
      .then(async (values) => {
          let { data } = await resetUserPassword({
            variables: {
              resetToken:params.token,
              password:values.password,
              confirm:values.confirm,
            },
          });
          const { _id,email, token,role,company,workspace } = data.resetUserpassword;
          const workspaces = workspace.map((ele) => ele._id);
            dispatch({
              type: actions.SET_AUTH_USER,
              authUser: Object.freeze({
                _id,
                email,
                role: role.name,
                company: company ? company._id : null,
                workspaces: workspaces,
              }),
            });
            localStorage.setItem('token', token);
            navigate("/map");
            window.location.reload();
      })
      .catch((info) => {
        setLoading(false);
        console.log("Validate Failed:", info);
      });
        }
    return (
        <>
                <Header className='loginHeader'>
        <div className="nav-wrapper w-container">
        <div className="nav-logo">
            <a href="/" className="link-block-header-logo w-inline-block w--current"><Title level={4}>DeviceSpeak</Title></a>
          </div>
        </div>
        </Header>
    <Content className='PassContent'
          style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        maxHeight: "600px",
        borderRadius: 10,
        overflow: "hidden",
      }}
    >
        <Card className="Authcard"  bordered={true}   style={{
          textAlign: "center",
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
  }} >
        <Title level={4}>{t("NewPassword")}</Title>
        <Divider />
        <Form
        layout="vertical"
        form={form}
      initialValues={{
        remember: true,
      }}
      onFinish={submit}
    >
            <Form.Item
            name="password"
            label={t("Password")}
            rules={[
              {
                required: true,
                message: t("FormValidations.17"),
              },
            ]}
          >
            <Input.Password style={{ width: "100%" }} />
          </Form.Item>

          <Form.Item
            name="confirm"
            label={t("ConfirmPassword")}
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: t("FormValidations.18"),
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(t("FormValidations.19")));
                },
              }),
            ]}
          >
            <Input.Password style={{ width: "100%",marginBottom:24 }} />
          </Form.Item>

            <Form.Item>
              <Button block ghost
                style={{
                  textAlign: "center",
                  width: "100%",
                  borderRadius: "5px",

                }}
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
                onClick={handleClick}
              >
                {t("SetPassword")}
              </Button>
            </Form.Item>
            {error&&<Text type='danger'>{error.message}</Text>}
            </Form>
        </Card>
      </Content>
        </>
    )
}

export default ResetPassword;