import React , {Suspense,useEffect,useState} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './components/App/index.js';
import { StoreContext } from 'redux-react-hook';
import store from './store/index.js';
import './i18n.js'
import * as serviceWorkerRegistration from './serviceWorkerRegistration.js';
import reportWebVitals from './reportWebVitals.js';
import PWAPrompt from 'react-ios-pwa-prompt';
import Loading from "./components/Loading/Loading.js";


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
const RootComponent = () => {
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    const subscribeUser = async () => {
      try {
        const registration = await navigator.serviceWorker.ready;

        const subscription = await registration.pushManager.subscribe({
          userVisibleOnly: true,
          applicationServerKey:
            "BPN2HY26Y-jqp-maoi2sta7IfTg8N0SFk-4YW6_ovC3TpPIHV3iU_qkTXYw_8kdrGnSHyFRfImPc-qzUvSWmkVI",
        });

        localStorage.setItem("subscription", JSON.stringify(subscription));
        // setSubscription(subscription);
        setIsLoading(false); // Stop loading once the subscription is ready
      } catch (error) {
        console.error("Failed to subscribe the user:", error);
        setIsLoading(false); // Stop loading even if there's an error
      }
    };

    subscribeUser();
  }, []);

  // if (isLoading) {
  //   return <Loading />; // Show the loading component while subscription is loading
  // }

  return (
    <StoreContext.Provider value={store}>
    <Suspense fallback={<></>}>
    <Router basename={process.env.PUBLIC_URL}>
      <App /><PWAPrompt />
    </Router>
    </Suspense>
    </StoreContext.Provider>
  );
};
const root = createRoot(document.getElementById('root'));
root.render(<RootComponent />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
